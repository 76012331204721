import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AspectRatio, Flex } from '@chakra-ui/react';

import { GPPayLogoSidebar } from 'assets';
import { useAuth, useStore } from 'store';
import { Icon } from 'components';

import { SidebarButton, SidebarUserInfo } from '.';

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAuth((state) => state.user);

  const lookup = useStore((state) => state.lookup);
  const merchant = user?.Merchant as string;
  const lookUpMerchant = lookup?.Merchant as any;
  const shouldShowSettlementInfo = !!lookUpMerchant?.[merchant]?.ProviderFeatures?.SettlementInfo;

  const menuItems = useMemo(
    () => [
      {
        leftIcon: 'Dashboard' as const,
        onClick: () => {
          if (user.AllowViewPaymentSummary) {
            navigate('/');
          }
        },
        isDisabled: !user.AllowViewPaymentSummary,
        label: 'Dashboard',
        isActive:
          location.pathname.split('/')[1] === '' || location.pathname.split('/')[1] === 'dashboard',
      },
      {
        leftIcon: 'Transactions' as const,
        onClick: () => {
          if (user.AllowViewPaymentTransactions) {
            navigate('/transactions');
          }
        },
        isDisabled: !user.AllowViewPaymentTransactions,
        label: 'Transactions',
        isActive: location.pathname.split('/')[1] === 'transactions',
      },
      {
        leftIcon: 'Settlements' as const,
        onClick: () => {
          if (user.AllowViewPaymentSettlements) {
            navigate('/settlements');
          }
        },
        isDisabled: !user.AllowViewPaymentSettlements || shouldShowSettlementInfo === false,
        label: 'Settlements',
        isActive: location.pathname.split('/')[1] === 'settlements',
      },
      {
        leftIcon: 'Transactions' as const,
        onClick: () => {
          if (user.AllowViewPaymentReports) {
            navigate('/reports');
          }
        },
        isDisabled: !user.AllowViewPaymentReports,
        label: 'Reports',
        isActive: location.pathname.split('/')[1] === 'reports',
      },
    ],
    [user, navigate, location, shouldShowSettlementInfo],
  );

  return (
    <Flex
      fontFamily={'Public Sans'}
      sx={{ overflow: 'hidden' }}
      h="100%"
      bg={'secondary.800'}
      color={'white'}
      py={2}
      flexDir={'column'}
      w={'220px'}
      minW={'220px'}
    >
      <Flex
        p={'24px 12px'}
        mb={'16px'}
        justifyContent={'center'}
        borderBottom={'1px solid'}
        borderBottomColor={'gray.900'}
      >
        <AspectRatio w={'180px'} h={'30px'} backgroundColor={'gray.800'}>
          <img src={GPPayLogoSidebar} alt={'GuestPoint Pay Logo'} />
        </AspectRatio>
      </Flex>
      <SidebarUserInfo />
      {menuItems
        .filter((v) => !v.isDisabled)
        .map((v) => (
          <SidebarButton
            key={v.label}
            tooltipProps={{ hidden: !v.isDisabled }}
            leftIcon={<Icon variant={v.leftIcon} size={6} />}
            onClick={v.onClick}
            isDisabled={v.isDisabled}
            isActive={v.isActive}
          >
            {v.label}
          </SidebarButton>
        ))}
    </Flex>
  );
};
